/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

const Contact = ({ data }) => {
  const { wpPage: page } = data;
  return <Layout inner contact seo={page.seo}></Layout>;
};

export default Contact;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
    }
  }
`;
